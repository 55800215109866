import { environment } from '../environments/environment';

export const API_URLS = {
  get_user: `${environment.apiUrl}/user/current`,
  // tinode
  tinodeUsers: `${environment.apiUrl}/users`,
  // contacts
  contacts: `${environment.apiUrl}/contacts`,
  search: `${environment.apiUrl}/search`,
  // channels
  channels: `${environment.apiUrl}/channels`,
  channel: `${environment.apiUrl}/channel`,
  channelMute: `${environment.apiUrl}/channel/mute`,
  channelMessageStatus: `${environment.apiUrl}/messages/status`,
  // storage
  storageFiles: `${environment.storage}/files/chat`,
  // report
  report: `${environment.apiUrl}/report`,
  // shortcodes
  shortcodeFcUser: `${environment.apiUrl}/shortcode/fc-user`,
  shortcodeFcPhoto: `${environment.apiUrl}/shortcode/fc-photo`,
  shortcodeFcSection: `${environment.apiUrl}/shortcode/fc-section`,
  shortcodeFcFotohome: `${environment.apiUrl}/shortcode/fc-fotohome`,
  //preview link
  previewLink: `${environment.apiUrl}/preview-link`,
};

export const PATH_URLS = {
  chats: `chats`,
  blacklist: `blacklist`,
  login: 'login',
  private: 'private',
  channel: 'channel',
  group: 'group',
  info: 'info',
  add: 'add',
  chatAddAdministrators: 'add-administrators',

  // all menu
  imprint: 'imprint',
  dataProtection: 'data-protection',
};
